/* eslint-disable react/prop-types */
import React from 'react'
import {
  getArtworkDimension,
  artistDisplayName,
  calcPrice,
} from '../components/GalleryUtils'
import { Modal } from './Modal'
import PurchaseRequestForm from './PurchaseRequestForm'
import appConfig from '../appConfig'

export const getHeaderText = (artwork, artist) =>
  "Kaufantrag | '" + artwork.title + "' von " + artistDisplayName(artist)

const PurchaseRequestModal = ({ hide, artwork, artist }) => (
  <Modal>
    <div className="modal-backdrop" />
    <div className="modal" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content flex-column px-4 pt-3 w-100">
          <div className="d-flex mb-1 align-items-start justify-content-between">
            <h2>{getHeaderText(artwork, artist)}</h2>
            <button onClick={hide} className="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="mb-2">
            <p className="mb-1">
              <strong>Künstler: </strong>
              {artistDisplayName(artist)}
            </p>
            <p className="mb-1">
              <strong>Titel: </strong>
              {artwork.title}
            </p>
            <p className="mb-1">
              <strong>Technik: </strong>
              {artwork.technique}
            </p>
            <p className="mb-1">
              <strong>Maße: </strong>
              {getArtworkDimension(artwork)}
            </p>
            <p className="mb-1">
              <strong>Preis: </strong>
              {calcPrice(artwork, appConfig.includePorto) + ' €'}
            </p>
          </div>
          <div className="mb-2">
            <h2>Ja, ich würde diese Arbeit kaufen!</h2>
          </div>
          <div className="mb-2">
            <PurchaseRequestForm artwork={artwork} />
          </div>
        </div>
      </div>
    </div>
  </Modal>
)

export default PurchaseRequestModal
