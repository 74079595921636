import React from 'react'
import appConfig from '../appConfig'
import { firebase, firestore } from '../firebase'
import { ToggleContent } from './Modal'
import PurchaseRequestModal from './PurchaseRequestModal'

export const kbImagifyOrig = (filename) =>
  'https://imgs.kuboshow.de/images%2F' + filename

export const kbImagifyDyn = (filename, width = 320) =>
  `https://imgs.kuboshow.de/cdn-cgi/image/width=${width},format=auto/images%2F${filename}`

export const kbImagifyDynOld = (filename, width = 320) =>
  'https://images.weserv.nl/?url=imgs.kuboshow.de/images%2F' +
  filename +
  '&w=' +
  width

export const artistDisplayName = (artist) => {
  if (artist.pseudonym && artist.pseudonym.length > 0) {
    return artist.pseudonym
  } else {
    return artist.first_name + ' ' + artist.last_name
  }
}

export const artistSlugName = (artist) => {
  const slug =
    artist.pseudonym && artist.pseudonym.length > 0
      ? artist.pseudonym.toLowerCase().trim()
      : artist.last_name.toLowerCase() + '-' + artist.first_name.toLowerCase()
  return slug.trim()
}

export const getArtworkDimension = (artwork) =>
  artwork.width +
  ' x ' +
  artwork.height +
  (artwork.depth ? ` x ${artwork.depth}` : '') +
  ' cm'

export const calcPrice = (artwork, includePorto = false) =>
  artwork.portoActive && includePorto
    ? Number(artwork.price) + Number(artwork.porto)
    : Number(artwork.price)

export const calcPorto = (sideA, sideB) => {
  const versandwert = sideA + sideB
  const longerSide = sideA > sideB ? sideA : sideB
  const shorterSide = longerSide === sideA ? sideB : sideA

  let porto = 7
  if (versandwert > 179 || longerSide >= 118 || shorterSide >= 58) porto = 35
  if (versandwert > 279) porto = 45
  if (versandwert > 320) porto = 55
  return porto
}

export const getArtworkUnderline = (artwork, hidePrice = false) => (
  <span>
    <strong>{artwork.title + ' | '}</strong>
    {artwork.technique +
      ' | ' +
      getArtworkDimension(artwork) +
      (artwork.price && !hidePrice
        ? ` | ${calcPrice(artwork, appConfig.includePorto)} €`
        : '') +
      (artwork.info ? ` | ${artwork.info}` : '')}
  </span>
)

const trackArtworkModalOpen = async (artwork) => {
  try {
    const docRefArtwork = await firestore.collection('artworks').doc(artwork.id)
    if (docRefArtwork)
      docRefArtwork.update({
        trackArtworkModalOpen: firebase.firestore.FieldValue.increment(1),
      })
  } catch (error) {
    console.error(error, error?.message)
  }
}

export const Caption = ({ artist, artwork }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <p className="my-0">{getArtworkUnderline(artwork)}</p>
      {appConfig.showPurchaseRequestButtons &&
      new Date() > appConfig.showPurchaseRequestButttonsTime ? (
        <ToggleContent
          toggle={(show) => (
            <button
              onClick={show}
              className="btn btn-primary ml-3 text-uppercase font-weight-bold py-0"
            >
              Kaufantrag
            </button>
          )}
          content={(hide) => {
            trackArtworkModalOpen(artwork)
            return (
              <PurchaseRequestModal
                hide={hide}
                artwork={artwork}
                artist={artist}
              />
            )
          }}
        />
      ) : null}
    </div>
  )
}
