import React, { useState } from 'react'
import { useFormik } from 'formik'
import axios from 'redaxios'
import * as Yup from 'yup'

import { firestore } from '../firebase'
import appConfig from '../appConfig'

const PurchaseRequestForm = ({ artwork }) => {
  const [isErrorShown, setIsErrorShown] = useState(false)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const formik = useFormik({
    initialValues: {
      last_name: '',
      first_name: '',
      email: '',
      phone: '',
      consentChecked: undefined,
    },
    validationSchema: Yup.object({
      last_name: Yup.string()
        .max(25, 'Maximale Zeichenanzahl: 25')
        .required('Feld benötigt'),
      first_name: Yup.string()
        .max(25, 'Maximale Zeichenanzahl: 25')
        .required('Feld benötigt'),
      email: Yup.string()
        .email('Ungültiges Format der E-Mail-Adresse')
        .required('Feld benötigt'),
      phone: Yup.string()
        .max(20, 'Maximale Zeichenanzahl: 20')
        .required('Feld benötigt'),
      consentChecked: Yup.bool()
        .oneOf([true], 'Zustimmung benötigt')
        .required('Zustimmung benötigt'),
    }),
    onSubmit: async (values) => {
      // const { artworks, ...artist } = values
      try {
        await new Promise((r) => setTimeout(r, 1500))
        console.log('Submitted-Data: ', values)
        // Save artist data
        const docRefPurchaseRequest = await firestore
          .collection('purchaseRequests')
          .doc()
        console.log('document reference: ', docRefPurchaseRequest)
        docRefPurchaseRequest.set({
          ...values,
          artworkId: artwork.id,
          artistId: artwork.artistId,
          userAgent: window?.navigator?.userAgent ?? '',
          // typeof window !== 'undefined' && window?.navigator?.userAgent,
        })
        const mailResult = await axios({
          method: 'post',
          url: appConfig.functions.onPurchaseRequest,
          data: JSON.stringify({
            ...values,
            artworkId: artwork.id,
            artistId: artwork.artistId,
            prDocId: docRefPurchaseRequest.id,
          }),
        })
        console.info('axios result: ', mailResult)
        if (mailResult.data === 'success') {
          setIsFormSubmitted(true)
          setIsErrorShown(false)
        } else {
          setIsErrorShown(true)
          const docRefFailedApp = await firestore
            .collection('failed_applications')
            .doc()
          docRefFailedApp.set({
            ...values,
            artworkId: artwork.id,
            artistId: artwork.artistId,
            userAgent: window?.navigator?.userAgent ?? '',
          })
        }
      } catch (error) {
        console.error(error, error?.message)
        setIsErrorShown(true)
        const docRefFailedApp2 = await firestore
          .collection('failed_applications')
          .doc()
        docRefFailedApp2.set({
          ...values,
          artworkId: artwork.id,
          artistId: artwork.artistId,
          userAgent: window?.navigator?.userAgent ?? '',
        })
      }
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="row">
        <div className="form-group col-6">
          <input
            id="first_name"
            placeholder="Mein Vorname"
            {...formik.getFieldProps('first_name')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.first_name && formik.errors.first_name ? (
            <div className="text-danger">{formik.errors.first_name}</div>
          ) : null}
        </div>
        <div className="form-group col-6">
          <input
            id="last_name"
            placeholder="Mein Nachname"
            {...formik.getFieldProps('last_name')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.last_name && formik.errors.last_name ? (
            <div className="text-danger">{formik.errors.last_name}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <input
            id="email"
            {...formik.getFieldProps('email')}
            placeholder="Meine E-Mail-Adresse"
            type="email"
            className="form-control my-input input-md"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <input
            id="phone"
            placeholder="Meine Telefonnummer"
            {...formik.getFieldProps('phone')}
            type="text"
            className="form-control my-input input-md"
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="text-danger">{formik.errors.phone}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <div className="form-check">
            <input
              id="consentChecked"
              {...formik.getFieldProps('consentChecked')}
              type="checkbox"
              className="form-check-input input-md"
            />
            <label className="form-check-label" htmlFor="consentChecked">
              Ich habe die{' '}
              <a
                href={appConfig.links.purchaseRequestGTC}
                target="_blank"
                rel="noreferrer"
              >
                AGBs
              </a>{' '}
              gelesen und akzeptiert
            </label>
          </div>
          {formik.touched.consentChecked && formik.errors.consentChecked ? (
            <div className="text-danger">{formik.errors.consentChecked}</div>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="form-group col-12">
          <p>
            Sie erhalten in Kürze eine Bestätigungsmail zu Ihrem Kaufantrag. Je
            nach Besucheraufkommen auf der Seite kann dies ein paar Stunden
            dauern.
          </p>
        </div>
      </div>
      {!isFormSubmitted && (
        <div className="row justify-content-center">
          <div className="form-group">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                formik.isSubmitting ||
                !formik.values.consentChecked ||
                Object.keys(formik.errors).length > 0
              }
            >
              {formik.isSubmitting && (
                <span
                  className="spinner-border spinner-border-sm mr-1"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              <span>KAUFANTRAG senden</span>
            </button>
          </div>
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-10 col-md-8 text-center">
          {isFormSubmitted && (
            <p className="text-success">
              Ihr Kaufantrag wurde erfolgreich erfasst. Vielen Dank!
            </p>
          )}

          {isErrorShown && (
            <p className="text-danger">
              Ihr Kaufantrag konnte nicht gesendet werden. Bitte versuchen Sie
              es noch einmal zu einem späteren Zeitpunkt oder informieren Sie
              den Administrator der Webseite.
            </p>
          )}
        </div>
      </div>
    </form>
  )
}

export default PurchaseRequestForm
