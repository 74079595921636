import React, { useState } from 'react'
import ReactDOM from 'react-dom'
// import { navigate } from '@reach/router'

// eslint-disable-next-line react/prop-types
export const ToggleContent = ({ toggle, content, hashID = false }) => {
  const [isShown, setIsShown] = useState(false)
  const hide = () => {
    setIsShown(false)
    if (hashID) history.replaceState({}, hashID, '')
  }
  const show = () => {
    if (hashID) history.replaceState({}, '', hashID) // navigate('?' + hashID)
    setIsShown(true)
  }

  return (
    <React.Fragment>
      {toggle(show)}
      {isShown && content(hide)}
    </React.Fragment>
  )
}

export const Modal = ({ children }) =>
  ReactDOM.createPortal(
    <div>{children}</div>,
    document.getElementById('portal')
  )
